<template>
	<v-container>
		<v-card>
			<v-card-title align="left" class="headline primary gen-journal-title">
				<h5>Payments</h5>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="filteredItems"
				sort-by="document_number"
				class="elevation-1"
				:search="search"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-spacer></v-spacer>
						<v-layout wrap>
						<v-flex md3 />
						<v-spacer/>
						<v-flex xs12 sm6 md3>
							<v-text-field append-icon="mdi-calendar" label="Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
							<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
								<v-date-picker
									v-model="month_year"
									type="month"
									@change="month_year_picker = false; getData()"
								></v-date-picker>
							</v-dialog>
						</v-flex>
						<v-spacer/>
						<v-flex xs12 sm6 md5>
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Search"
								single-line
								hide-details
							></v-text-field>
						</v-flex>
						</v-layout>
					</v-toolbar>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<template v-if="item.paid === 'Unpaid'">
						<v-icon color="primary" class="mr-2" small @click="paymentRequest(item)"
							>mdi-clipboard-check-multiple-outline</v-icon>
						<v-icon color="primary" v-if="item.payment_amount === '0.00'" class="mr-2"  small @click="cancelTransaction=true;po_number=item.po_number;edit_by=item.edit_by"
							>mdi-close-circle-outline</v-icon>
						<v-icon color="red" v-else class="mr-2" small
						>mdi-close-circle-outline</v-icon>
						<v-icon color="primary" class="mr-2" small @click="printPO(item)">
							mdi-printer-eye
						</v-icon>
					</template>
					<template v-else>
						<v-icon color="red" class="mr-2" small
							>mdi-clipboard-check-multiple-outline</v-icon>
						<v-icon color="red" class="mr-2" small
							>mdi-close-circle-outline</v-icon>
						<v-icon color="primary" class="mr-2" small @click="printPO(item)">
							mdi-printer-eye
						</v-icon>
					</template>
				</template>
			</v-data-table>
			<div class="text-center">
				<v-dialog
					v-model="viewDialog"
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
				>
					<v-trx-detail ref="detailTrxdisb" :batchDetails.sync="batchDetails" :documentDetails.sync="documentDetails" :viewDialog.sync="viewDialog"></v-trx-detail>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="attachmentDialog"
					:items="batchDetails"
					:documentDetails="documentDetails"
					width="1200"
				>
					<v-trx-downloads ref="attachmentTrxdisb" :batchDetails.sync="batchDetails" :attachmentDialog.sync="attachmentDialog"></v-trx-downloads>
				</v-dialog>
			</div>
		</v-card>
		<div class="text-center">
			<v-dialog
				v-model="cancelTransaction"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Cancel PO - {{po_number}}
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to cancel Purchase Order {{po_number}}?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="cancelPayment = true"
						>
							Yes
						</v-btn>
						<v-btn
							color="red"
							text
							@click="cancelTransaction = false"
						>
							No
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center">
			<v-dialog
				v-model="cancelPayment"
				persistent max-width="600"
			>
				<v-card>
				<v-card-title
					class="headline primary white--text"
					primary-title
				>
					Reason for Cancellation
				</v-card-title>
				<br>
				<v-card-text>
					Please state the reason:
					<v-text-field v-model="approve_remarks" label="Remarks" required></v-text-field>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
					color="primary"
					text
					@click="cancelRequest(po_number)"
					>
					Cancel Purchase Order
					</v-btn>
					<v-btn
					color="red"
					text
					@click="cancelPayment = false"
					>
					Close
					</v-btn>
				</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center">
				<v-dialog
					v-model="createDisbDialog"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Create Payment
						</v-card-title>
						<br>
						<v-card-text>
							Sorry, the accounting period for this month is already <b>CLOSED</b>.
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="createDisbDialog = false"
							>
								OK
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
	</v-container>
</template>

<script>
import numeral from 'numeral';
// import detailJournal from './detailJournal.vue';
// import attachmentJournal from './attachmentJournal.vue';
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import jsonToFormData from 'json-form-data';

export default {
	data() {
		return {
			approve_remarks : '',
			cancelPayment: false,
			po_number: '',
			edit_by: '',
			module_name: 'Payment',
			role_access: 1,
			trxnStatus:1,
			grid_data: [],
			trxnStatusName: 'Drafting',
			search: '',
			cancelTransaction: false,
			month_year: '',
			month_year_picker: false,
			currentUserFullName: '',
			unsavedTransaction: false,
			isApprover: false,
			batchDetails: [],
			documentDetails:[],
			fileDetails: [],
			data: [],
			dataCoas: [],
			nextPageCoas: '',
			nextPage: '',
			nextPageTrx: '',
			headers: [
				{
					text: 'PO Number',
					align: 'start',
					sortable: false,
					value: 'po_number',
				},
				{ text: 'Shipping Date', value: 'shipping_date' },
				{ text: 'PO Date', value: 'po_date' },
				{ text: 'Preparer', value: 'preparer' },
				{ text: 'PO Amount', value: 'amount', align: 'right' },
				{ text: 'Payment Amount', value: 'payment_amount', align: 'right' },
				{ text: 'Status', value: 'paid' },
				{ text: 'Actions', value: 'action', sortable: false }
			],
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			months: [
				{name: 'January', value: 1},
				{name: 'February', value: 2},
				{name: 'March', value: 3},
				{name: 'April', value: 4},
				{name: 'May', value: 5},
				{name: 'June', value: 6},
				{name: 'July', value: 7},
				{name: 'August', value: 8},
				{name: 'September', value: 9},
				{name: 'October', value: 10},
				{name: 'November', value: 11},
				{name: 'December', value: 12},
			],
			selectedYear: null,
			createDisbDialog: false,
			selectedMonth: null,
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			]
		};
	},
	computed: {
		...mapGetters({
			trxdisbs: 'trxdisb/trxdisbs',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			editedItem: 'trxdisb/editedItem',
			defaultItem: 'trxdisb/defaultItem',
			dialog: 'trxdisb/dialog',
			editedIndex: 'trxdisb/editedIndex',
			editDialog: 'trxdisb/editDialog',
			viewDialog: 'trxdisb/viewDialog',
			attachmentDialog: 'trxdisb/attachmentDialog',
			valid: 'trxdisb/valid',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			dist_data: 'trxdisb/dist_data',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
		}),
		years() {
			const year = new Date().getFullYear();
			const generateYears = Array.from({ length: year - 1900 },(value, index) => 1901 + index);
			generateYears.sort();
			return generateYears.reverse();
		},
		filteredItems() {
			let app = this;
			let txn = app.grid_data;
			let txnFiltered = txn.filter(det => det.status === 7);
			txnFiltered.forEach(det => {
				det.paid = det.is_paid === 0 ? 'Unpaid': 'Paid'; 
				det.shipping_date =  format(new Date(det.shipping_date), 'yyyy-MM-dd');
				det.po_date =  format(new Date(det.po_date), 'yyyy-MM-dd');
				det.amount = this.formatAmount(det.amount);
				det.payment_amount = this.formatAmount(det.payment_amount);
				det.remaining_amount = this.formatAmount(det.remaining_amount);
			});
			return txnFiltered;
		}
	},
	watch: {
		editedIndex: {
			handler() {
			},
			deep: true
		},
		trxdisbs: {
			handler() {
			},
			deep: true
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		trxdisbDetails: {
			handler(val) {
				this.$data.batchDetails.date = format(new Date(), 'yyyy-MM-dd');
				this.$data.batchDetails.document_type = val[0].document_type;
				this.$data.batchDetails.is_confidential = val[0].is_confidential;
				this.$data.batchDetails.bank_account_id = val[0].bank_account_id;
				this.$data.batchDetails.currency_id = val[0].currency_id;
				this.$data.batchDetails.dollar_rate = val[0].dollar_rate;

								

				val.forEach(docData => {
					let filterDetails = [];

					if (docData.document_type === 'CW') {
						filterDetails = docData.cw_details.filter(detail => detail.document_number === docData.document_number);
					} else {
						filterDetails = docData.details.filter(detail => detail.document_number === docData.document_number);
					}


					//computes the total debit in a specific document
					const totalDebit = filterDetails.reduce((sum, details) => {
						if (details.debit_amount === '') {
							details.debit_amount = 0;
						}

						return sum + parseFloat(details.debit_amount);
					}, 0);

					//computes the total credit in a specific document
					const totalCredit = filterDetails.reduce((sum, details) => {
						if (details.credit_amount === '') {
							details.credit_amount = 0;
						}

						return sum + parseFloat(details.credit_amount);
					}, 0);

					docData.overallDebit = totalDebit;
					docData.overallCredit = totalCredit;

					docData.disbursement_type = docData.regular_trx_id > 0 ? 1 : 2;
					docData.regular_trx_type = (docData.disbursement_type == 1 && docData.regular_trx_id > 0) ? 1 : 2;
					docData.regular_trx_selected = docData.regular_trx_id !== process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER ? docData.regular_trx_id : 0;
				});

				this.$data.documentDetails = val;

			},
			deep: true
		},
		acctperiodmains:{
			handler(){
				this.getAcctperiodmain();
			}
		}
	},
	async mounted() {
		const that = this;
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		const res = await that.$store.dispatch('trxpurchaseorder/getGridPO', date);
		that.grid_data = res.data.data;
	},
	methods: {
		async cancelRequest (item) {
			const self = this;
			var payload = {
				status: 10,
				edit_by: this.edit_by,
				_method: 'PUT',
				approve_remarks: this.approve_remarks,
				po_number: item
			};
			const resDetail = await self.$store.dispatch('trxpurchaseorder/updateRequestStatus', payload);
			if(resDetail.status == 200){
				this.$swal.fire({
					type: 'success',
					title: 'Sucessfully Cancelled Purchase Order!',
					timer: 3000
				}).then(() => {
					window.location.href = '/trx-payment';
				});
			} else {
				this.$swal.fire({
					type: 'error',
					title: 'Unable to Cancel Purchase Order ' + item + '!',
					timer: 3000
				});
				this.cancelTransaction = false;
			}
		},
		printPO (item) {
			let routeData = this.$router.resolve({path: '/purchase-order-print/'+item.po_number});
			window.open(routeData.href, '_blank'); 
		},
		paymentRequest(item) {
			console.log(item);
			let transaction = '';
			let trans_type = '';
			let url = '';
			let trans = '';
			if(item.payment_mode === 1){
				transaction = 'general_journal';
				trans_type = 'JE';
				url = 'trx-gen-journal/create';
				trans = 'journal_entry';
			} else {
				transaction = 'disbursement';
				trans_type = 'DM';
				url = 'trxdisb/create';
				trans = 'debit_memo';
			}


			this.$store.commit('trxpurchaseorder/SET_PO_REQUEST', item);
			let date = format(new Date(), 'yyyy-MM-dd');
			let currentMonth = getMonth(parseISO(date));
			let currentYear = getYear(parseISO(date));

			let isBookOpen = true;
			
			this.data.forEach(account_period => {
				if (account_period.period_no === (currentMonth + 1) && account_period.fiscal_year === currentYear) {
					if (account_period[transaction] === 'CLOSED') {
						isBookOpen = false;
					} else {
						isBookOpen = true;
					}
				} 
			});

			if (isBookOpen === true) {
				let data = {
					document_type: trans_type,
					document_date: date,
					edit_by: this.currUser.id,
				};
				if(this.dist_data){
					if(trans in this.dist_data){
						const distData = this.dist_data;
						if(!('batch_number' in distData[trans])){
							this.$store.dispatch('trxdisb/getDefaultBatchNumber', data);
						} else {
							this.$router.push(url);
						}
					}else{
						this.$store.dispatch('trxdisb/getDefaultBatchNumber', data);
					}
				} else {
					this.$store.dispatch('trxdisb/getDefaultBatchNumber', data);
				}
			} else {
				this.createDisbDialog = true;
			}
			
		},
		editItem(item) {
			let toPass = btoa(JSON.stringify(item));
			window.open('trx-purchase-order/edit/'+toPass);
		},
		deleteItem(item) {
			this.$swal.fire({
				title: 'Delete!',
				text:
				item.batch_number +
				'(' +
				item.document_number +
				')' +
				'? You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then(result => {
				if (result.value) {
					this.$store.dispatch('trxdisb/setisLoading', true);
					this.$store.dispatch('trxdisb/deleteTrxdisb', item);
				}
			});
		},
		cancelJournalEntry(item) {
		
			
			this.$swal.fire({
				title: 'Cancel Journal Entry No. ' + item.batch_number,
				text: 'Are you sure you want to cancel this entry?',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'CANCEL JOURNAL ENTRY',
				cancelButtonText: 'CANCEL'
			}).then(result => {
				if (result.value) {
					let data = {};
										
					data.batch_number = item.batch_number;
					data.value = {
						processing: 0,
						status: 10,
						approved_remarks: '',
						document_type: 'JE',
						_method: 'PUT'
					};
					
					var options = {
						initialFormData: new FormData(),
						showLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};

					let convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/setisLoading', true);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}
			});
		},
		generateBIRForm(item) {
			this.$router.push({
				name: 'bir-form',
				params: {
					data: item
				} 
			});
		},
		printItem(item) {
			let routeData = this.$router.resolve({path: '/preview-trx/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 
		},
		printItemCheque(item){
			let routeData = this.$router.resolve({path: '/preview-cheque/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 

			let routeDataVoucher = this.$router.resolve({path: '/preview-voucher/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeDataVoucher.href, '_blank'); 
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		setBatchDocument(){
			// window.open('trx-purchase-order/create', '_blank'); 
			this.$router.push('trx-purchase-order/create');
		},
		retrieveTransaction(){

		},
		removeTransaction(){
			let data = this.dist_data;
			
			if('journal_entry' in data){
				if('details' in data.journal_entry){
					delete data.journal_entry.details;
				}
				if('headers' in data.journal_entry){
					delete data.journal_entry.headers;
				}
				if('date' in data.journal_entry){
					delete data.journal_entry.date;
				}
				if('bank_account_id' in data.journal_entry){
					delete data.journal_entry.bank_account_id;
				}
				if('isConfidential' in data.journal_entry){
					delete data.journal_entry.isConfidential;
				}
				if('disbursement_type' in data.journal_entry){
					delete data.journal_entry.disbursement_type;
				}
			}
			if('cheque' in data){
				if('details' in data.cheque){
					delete data.cheque.details;
				}
				if('headers' in data.cheque){
					delete data.cheque.headers;
				}
				if('date' in data.cheque){
					delete data.cheque.date;
				}
				if('bank_account_id' in data.cheque){
					delete data.cheque.bank_account_id;
				}
				if('isConfidential' in data.cheque){
					delete data.cheque.isConfidential;
				}
				if('disbursement_type' in data.cheque){
					delete data.cheque.disbursement_type;
				}
			}
			if('online' in data){
				if('details' in data.online){
					delete data.online.details;
				}
				if('header' in data.online){
					delete data.online.headers;
				}
				if('date' in data.online){
					delete data.online.date;
				}
				if('bank_account_id' in data.online){
					delete data.online.bank_account_id;
				}
				if('isConfidential' in data.online){
					delete data.online.isConfidential;
				}
				if('disbursement_type' in data.online){
					delete data.online.disbursement_type;
				}
			}
			this.$store.commit('trxdisb/SET_DIST_DATA', data);
			this.unsavedTransaction = false;
		},
		async getAcctperiodmain(){
			this.data = this.acctperiodmains.data;
			this.nextPage= this.acctperiodmains.next_page_url;
			let page = 2;

			while (this.nextPage !== null){
				await this.$store.dispatch('acctperiodmain/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page++;
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		async getData(){
			const that = this;
			var date = that.month_year + '-01';
			const res = await that.$store.dispatch('trxpurchaseorder/getGridPO', date);
			that.grid_data = res.data.data;
		}
	}
};

</script>

<style scoped>
	.gen-journal-title h5 {
		color: #FFF;
		font-size: 25px;
	}
	
	.date-filter {
		display: flex;
		flex-direction: row;
		position: relative;
		top: 10.5px; 
		right: 10px;
	}
	.date-filter-picker {
		width: 150px;
	}
	.date-filter-picker:first-child {
		margin-right: 10px;
	}

	.document-card-title {
		padding: 0;
	}
</style>
